
import { Vue, Component, Watch, Prop, Emit } from "vue-property-decorator";
import CollapseList from "@/views/attributeList/components/CollapseList.vue";
import RelationInfo from "@/views/attributeList/components/RelationInfo.vue";
import TagList from "@/views/attributeList/components/tagList.vue";
import CheckList from "@/views/attributeList/components/checkList.vue";
import LightGruop from "@/views/attributeList/components/lightGroup.vue";
import CoordinateInfo from "@/views/attributeList/components/coordinatesInfo.vue";
import { getLightGroupList} from "@/config/api";
import CollapseCrad from "@/components/CollapseComponent/CollapseCrad.vue";

@Component({
    components: {
        RelationInfo,
        CollapseList,
        TagList,
        CheckList,
        LightGruop,
        CoordinateInfo,
        CollapseCrad
    },
})
export default class AttributeList extends Vue {
    @Prop() selectedFeatures?: any[];
    @Prop() isShowTagList?: boolean
    @Prop() tagListData?: any[]
    @Prop() checkListData?: any[]
    @Prop() selectedCoordinatesInfo?: any[]
    
    isShowCheck: boolean = false
    isShowLightList: boolean = false
    pageSize: number = 10;
    currentPageIndex: number = 1;
    currentFeatures: any[] = [];
    isShowRelationInfo: boolean = false;
    selectFeatures: any = {}
    selectedId: any = null;
    spinning: boolean = false
    featureList: any[] = [];
    activeKey: any = [];
    isShowCoordinateInfo: boolean = false
    lightListData: any[] = []

    attrActiveKey: any[] = []
    relationIconActiveKey: any[] = []
    labelListIconActiveKey: any[] = []
    checkListIconActiveKey: any[] = []
    lightGrouplListIconActiveKey: any[] = []
    coordListIconActiveKey: any[] = []
    
    $refs!: {
      LightGruop: LightGruop;
      CoordinateInfo:CoordinateInfo
      RelationInfoRef: RelationInfo
    };

    @Watch("tagListData")
    tagListDataChange(val: any[]) {
      if(val.length > 0) {
        this.labelListIconActiveKey = ['3']
      } else {
        this.labelListIconActiveKey = []
      }
    }

    @Watch("checkListData")
    checkListDataChange(val: any[]) {
      if(val.length > 0) {
        this.checkListIconActiveKey = ['4']
      } else {
        this.checkListIconActiveKey = []
      }
    }

    @Watch("selectedCoordinatesInfo")
    selectedCoordinatesInfoChange(val: any[]) {
      if (val.length > 0) {
        this.coordListIconActiveKey = ['6']
      } else {
        this.coordListIconActiveKey = []
      }
    }

    refresh(currentKey: any) {
      if (this.activeKey.length) {
        this.activeKey = this.activeKey.filter((key: any) => key !== currentKey)
      }
    }

    @Watch("selectedFeatures")
    change(features: any[]) {
        if (!features.length) {
            this.isShowRelationInfo = false;
            this.featureList = []
        }
        this.$nextTick(() => {
          this.currentFeatures = features;
        })
        if (features.length > 0) {
          this.attrActiveKey = ['1']
        } else {
          this.attrActiveKey = []
          this.relationIconActiveKey = []
        }
    }

    @Watch('isShowLightList')
    isShowLightListChange(newVal:boolean){
      if(newVal){
        this.getGroupList()
      }

    }

    //获取灯组列表
    getGroupList() {
      getLightGroupList().then((res: any) => {
        this.lightListData = res
        if (res.length > 0) {
         this.lightGrouplListIconActiveKey = ['5']
        } else {
         this.lightGrouplListIconActiveKey = []
        }
      })
    }

    // 点击选中的要素属性id
    async selectedFeatureId(feature: any) {

      this.selectFeatures = feature;
      this.isShowRelationInfo = true;
      this.relationIconActiveKey = ['2'];
      this.selectedId = feature.id;
      this.$nextTick(() => {
        this.$refs.RelationInfoRef.showInfoModal(this.selectedId);
      })

    }

    selectedMap(key: any) {
        this.$emit("selectedMap", key);
    }

    @Emit('removeSelectMap')
    removeSelectMap() {}

   // 检查列表显示隐藏
    isShowCheckList(type: boolean) {
      this.isShowCheck = type
    }
}
