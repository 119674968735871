
import {Component, Vue, Watch, Prop,InjectReactive} from "vue-property-decorator";
import {eventBus} from '@/utils/eventBus';
import {delTag} from '@/config/api'

@Component
export default class CollapseList extends Vue {
  activeKey: any[] = [];
  currentClickIndex: number = 0
  tagData: any[] = []

  @InjectReactive() tagListData!: any[]

  @Watch('tagListData', { immediate: true })
  dataChange(newData: any[]) {
    this.tagData = newData;
  }

  delTag(index: number, item: any) {
    delTag(item.featureFlagId).then((res: any) => {
      eventBus.$emit('updateTag', {type: 'del', id: item.featureFlagId})
      this.tagData.splice(index, 1)
    })
  }

  clickId(item: any) {
    eventBus.$emit('updateTag', {type: 'selectTag', id: item.featureFlagId})
  }
}
