import { MapBrowserEvent } from "ol";
import DrawBase from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
export default class Draw extends DrawBase {
    constructor(options: any) {
        super(options);
    }

    // 点击ESC按键回退到上一步
    handleEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (mapBrowserEvent.originalEvent.button == 2)  {
            const _this: any = this;
            _this.abortDrawing();
            return false
        }
        if (mapBrowserEvent.type == 'keydown' && mapBrowserEvent.originalEvent.key == 'Escape') {
            this.removeLastPoint();
        }
        return super.handleEvent(mapBrowserEvent);
    }

    setSource(source: VectorSource<any>) {
        const _this: any = this;
        _this.source_ = source;
    }

    getSource(): VectorSource<any> {
        const _this: any = this;
        return _this.source_;
    }
}