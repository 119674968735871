
import { Vue, Component } from "vue-property-decorator";
import { importFeatureApi } from "@/config/api";

@Component
export default class PosPopup extends Vue {
    visible: boolean = false;
    confirmLoading: boolean = false;

    fileList: any = [];
    zlevel: number  = 0;

    handleRemove(file: any) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList
    }

    beforeUpload(file: any) {
        this.fileList = [...this.fileList, file];
        return false;
    }

    show() {
        this.visible = true;
    }

    handleOk() {
        const { fileList } = this;
        if (fileList.length == 0) {
            this.$message.warn('请先上传文件，再做下一步操作');
            return;
        }
        const formData = new FormData();
        fileList.forEach((file: any) => {
            formData.append('files', file);
        });

        this.confirmLoading = true;
        importFeatureApi(formData, this.zlevel).then(() => {
            this.$message.success('上传成功')
            this.handleCancel();
            this.$emit('refreshMap')
        }).catch((error:any)=>{
          console.log("error", error)
        }).finally(() => {
            this.confirmLoading = false;
        })
    }


    handleCancel() {
        this.fileList = [];
        this.visible = false;
        this.confirmLoading = false;
    }
}
