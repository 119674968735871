// 公共属性配置


// 车道线颜色
export const boundaryTypeMap = {
    "0": "车道边界",
    "1": "道路边界",
    "2": "车道边界&道路边界"
}

// 车道线颜色
export const lineColorMap = {
    "none": "无颜色",
    "white": "白色",
    "yellow": "黄色",
    "blue": "蓝色",
    "other": "其它",
}

// 边线类型
export const lineTypeMap = {
    "virtual": "虚拟标线",
    "solid": "单实线",
    "broken": "单虚线",
    "double_solid": "双实线",
    "solid_broken": "左实右虚",
    "broken_solid": "左虚右实",
    "variable_direction": "可变导向车道线",
    "short_borken": "短粗虚线",
    "other": "其它"
}
// 物理隔离带类型
export const isolationMap = {
    "none": "无物理隔离带",
    "fence": "防护栏",
    "curb": "路缘石",
    "drainage": "沟渠",
    "wall": "墙面",
    "pier": "隔离墩",
    "steep": "悬崖",
    "other": "其它",
}

// 车道类型
export const laneTypeMap = {
    "virtual": "虚拟车道", // 描述虚拟车道
    "driving": "驾驶", // 描述了一条“正常”可供行驶、不属于其他类型的道路
    "shoulder": "路肩", // 描述了道路边缘的软边界
    "parking": "泊车", // 描述了带停车位的车道
    "sidewalk": "人行道", // 描述了允许行人在上面行走的道路
    "border": "边界", // 描述了道路边缘的硬边界。其与正常可供行驶的车道拥有同样高度
    "biking": "自行车道", // 描述了专为骑自行车者保留的车道
    "curb": "路缘", // 描述了路缘石。路缘石与相邻的行车道在高度有所不同
    "stop": "停", // 高速公路的硬路肩，用于紧急停车
    "connection": "连接车道", // 般用于路口内描述车道链接的虚拟车道，可重叠
    "none": "无", // 描述了道路最远边缘处的空间，并无实际内容。其唯一用途是在（人类）驾驶员离开道路的情况下，让应用记录OpenDRIVE仍在运行
    "restricted": "限制", // 描述了不应有车辆在上面行驶的车道。该车道与行车道拥有相同高度。通常会使用实线以及虚线来隔开这类车道
    "median": "分隔带", // 描述了位于不同方向车道间的车道。在城市中通常用来分隔大型道路上不同方向的交通
    "exit": "出口", // 描述了用于平行于主路路段的车道。主要用于减速
    "entry": "入口", // 描述了用于平行于主路路段的车道。主要用于加速
    "on_ramp": "加速车道", // 由乡村或城市道路引向高速公路的匝道
    "off_ramp": "减速车道", // 驶出高速公路，驶向乡村或城市道路所需的匝道
    "connectingRamp": "连接匝道", // 连接两条高速公路的匝道。例如高速公路路口
    "drivable_parking": "可驾驶停车道",
    "drivable_shoulder": "可驾驶肩道",
    "hov": "HOV车道",
    "bus_lane": "公交车道",
    "brt": "BRT车道",
    "other": "其它"
}

// 车道允许行驶对象
export const objectTypeMap = {
    "all_motor_vehicle": "全部机动车",
    "car": "小汽车",
    "bus": "公交车",
    "non_motor_vehicle": "非机动车",
    "mix_lane": "机非混合",
    "train": "火车",
    "hov_vehicle": "HOVE车辆",
    "coach_bus": "中大型客车",
    "trailer": "挂车",
    "special_vehicle": "特殊作业车",
    "taxi": "出租车",
    "walker": "行人",
    "motorcycle": "摩托车",
    "emergency_lane": "应急车道",
    "other": "其它"
}

// 道路类型
export const situationType = {
    "town": "城市",
    "highway": "高速",
    "countryside": "乡村",
    "urban_expressway": "城市快速路"
}

// 停止线类型
export const stopLineType = {
    "solid": "实体停止线（单线）",
    "double_solid": "带停车标志的让停线（双实线）",
    "double_broken": "带让路标志的让停线（双虚线）",
    "virtual": "虚拟停车线（因人行横道等）",
    "other" : "其它"
}


// 联通区域类型
export const connectivityAreaType = {
    "intersection": "路口",
    "lane_change": "车道变化区",
    "toll_station": "收费站",
    "other" : "其它"
}

// 交通信号灯类型
export const lightTurnType = {
    "forward": "直⾏",
    "turn_left": "左转",
    "turn_right": "右转",
    "u_turn" : "掉头",
    "crosswalk" : "⼈⾏横道",
    "non_motor_vehicle" : "⾮机动⻋道",
    "lane" : "车道",  //  一般作用与车道，指示某指定车道是否允许通行。
    "crossing_signal" : "道⼝",  // 一般用于铁路道口等，描述道口是否可通行信号灯
    "other" : "其它",
}


// 地面标识类型
export const roadMarkType = {
    "directional_arrow": "转向箭头",
    "speed_hump": "减速带",
    "speed_limit_symbol": "减速标识",
    "no_turn_symbol" : "禁止转向标识",
    "maximum_speed_limit_sign" : "限速上限提示",
    "minimum_speed_limit_sign" : "限速下限提示",
    "caution_pedestrian_crossing_sign" : "注意行人",
    "keep_safe_distance_sign" : "保持车距提示",
    "no_stopping_line" : "禁停线",
    "no_u_turn_sign" : "禁止掉头",
    "crosswalk": "人行横道",
    "other" : "其它",
}

// 地面标识.转向箭头 （多选）
export const directionalArrowContent = {
    "straight_through": "直行",
    "turn_right": "右转",
    "turn_left": "左转",
    "make_a_right_u_turn": "右掉头",
    "make_a_left_u_turn": "左掉头",
    "left_turn_round": "左转加掉头",
    "stright_round": "直行加掉头",
    "straight_right": "直行或右转",
    "straight_left": "直行或左转",
    "straight_right_left": "直行或右转或左转",
    "right_left": "右转或左转",
    "right_merge": "右弯",
    "left_merge": "左弯",
    "other" : "其它",
}

// 地面标识.减速带.content
export const speedHumpContent = {
    "protrusion": "凸起",
    "paint": "油漆 ",
    "other" : "其它",
}


export const trafficSignType = {
    "general_caution_sign": "一般警示标志",
    "intersection_sign": "交叉路口标志",
    "animal_crossing_sign": "注意牲畜标志",
    "children_crossing_sign": "注意儿童标志",
    "sharp_turn_sign": "急转弯标志",
    "steep_hill_sign": "陡坡标志",
    "narrow_road_sign": "窄路标志",
    "obstacle_ahead_sign": "注意障碍物标志",
    "tunnel_sign": "隧道标志",
    "crosswind_sign": "注意横风标志",
    "traffic_signal_sign": "注意信号灯标志",
    "accident_prone_section_sign": "事故易发路段标志",

    "no_entry_sign": "禁止通行标志",
    "do_not_enter_sign": "禁止驶入标志",
    "no_motor_vehicles_sign": "禁止机动车通行标志",
    "no_cargo_vehicles_sign": "禁止载货汽车通行标志",
    "no_tricycles_sign": "禁止三轮机动车通行标志",
    "no_large_or_small_buses_sign": "禁止大型或小型客车通行标志",
    "no_trailers_sign": "禁止汽车拖、挂车通行标志",
    "no_non_motorized_vehicles_sign": "禁止非机动车通行标志",
    "no_motorcycles_sign": "禁止摩托车通行标志",
    "no_pedestrians_sign": "禁止行人通行标志",
    "no_left_or_right_turn_sign": "禁止向左或向右转弯标志",
    "no_straight_through_sign": "禁止直行标志",
    "no_left_and_right_turn_sign": "禁止向左向右转弯标志",
    "no_straight_through_or_left_turn_sign": "禁止直行和向左转弯",
    "no_straight_through_or_right_turn_sign": "禁止直行和向右转弯标志",
    "no_u_turn_sign": "禁止掉头标志",
    "no_overtaking_sign": "禁止超车标志",
    "end_of_no_overtaking_sign": "解除禁止超车标志",
    "no_parking_sign": "禁止车辆停放标志",
    "height_restriction_sign": "限制高度标志",
    "speed_limit_sign": "限制速度标志",
    "speed_bump_sign": "解除限制速度标志",
    "stop_yield_sign": "停车让行标志",
    "deceleration_yield_sign": "减速让行标志",
    "meeting_yield_sign": "会车让行标志",

    "straight_through_sign": "直行标志",
    "right_turn_sign": "右转弯标志",
    "left_and_right_turn_sign": "左转弯和右转弯标志",
    "straight_and_left_turn_sign": "直行和左转弯标志",
    "straight_and_right_turn_sign": "直行和右转弯标志",
    "straight_through_and_turn_sign": "直行和转弯标志",
    "keep_right_or_keep_left_sign": "靠右侧或靠左侧道路行驶标志",
    "directional_arrow_sign": "立交行驶路线标志",
    "roundabout_sign": "环岛行驶标志",
    "one_way_street_sign": "单行路标志",
    "minimum_speed_limit_sign": "最低限速标志",
    "crosswalk_sign": "人行横道标志",
    "lane_control_sign": "专用车道标志",
    "u_turn_permitted_sign": "允许掉头标志",
    "other" : "其它",
}

// 障碍物类型
export const obstacleType = {
    "ground_traffic_light" : "地面红绿灯",
    "police_station_booth" : "交警站立亭",
    "safety_island" : "安全岛",
    "other" : "其它",
}

// 杆类型
export const poleType = {
    "light_pole" : "灯杆",
    "gantry" : "龙门架",
    "l_shaped_pole" : "L型杆",
    "t_shaped_pole" : "T型杆",
    "other" : "其它",
}

