import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 创建路由实例并配置路由
const router = new VueRouter({
    mode: 'history',
    routes: [
        // 这里添加你的路由配置
    ]
});

export default router;
