
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { layers, layersTitle} from '@/config/layerConfig'
import { eventBus } from '@/utils/eventBus';
import { getFeatureRef, delFeatureRef } from "@/config/api";
import { isEmpty } from "@/utils/util";

import AddRelationInfo from "@/components/popup/AddRelationInfo.vue";
@Component({
  components: { AddRelationInfo }
})
export default class RelationInfo extends Vue {
  featureList: any[] = [];
  currentClickIndex: number = 0
  layers: any = layers
  layersTitle: any = layersTitle
  loading: boolean = false
  selectFeaRealtion: string[] = []
  selectedLayerType: any = null;
  currentFeature: any = {};

  @Prop() selectFeatures?: any
  selectedId?: any = null;

  $refs!: {
    AddRelationInfoRef: AddRelationInfo
  };
  
  showInfoModal(id: any) {
    this.selectedId = id;
    this.loadFeatureList();
  }

  async loadFeatureList() {
    this.featureList = [];
    this.loading = true;
    let res = await getFeatureRef(this.selectedId);
    let data : object[] = [];
    res.forEach(((item: any) => {
      let temp : any = {
          "featureId" : this.selectedId,
          "featureType" : this.selectFeatures.layerType,
          "refId" : item.id,  // 删除时的id
          "dellteRefType" : item.featureRefType, // 删除时的 reftype
          "content": ""
      }
      if (this.selectedId == item.refFeatureId) {
          temp = Object.assign(temp,{
            "refFeatureId" :  item.featureId,
            "refFeatureType" : item.featureType
          })
           // 正向
          if (this.selectFeatures.layerType == 1002 && item.featureRefType == 1002) {
             let tmp : any = {"after":"后车道","before":"前车道"}
             temp.content = isEmpty(item.sequence) ? "": tmp[item.sequence];
          }
      }
      if (this.selectedId == item.featureId) {
          temp = Object.assign(temp,{
            "refFeatureId" :  item.refFeatureId,
            "refFeatureType" : item.featureRefType,
          })
          // 反向
          if (this.selectFeatures.layerType == 1002 && item.featureRefType == 1002) {
            let tmp : any = {"before":"后车道","after":"前车道"}
            temp.content = isEmpty(item.sequence) ? "": tmp[item.sequence];
          }
      }

      data.push(temp);
    }))

    if (this.selectFeatures.layerType == 1002) {
        data.push({
          "featureId" : this.selectedId,
          "featureType" : this.selectFeatures.layerType,
          "refFeatureId" :  this.selectFeatures.leftBoundaryId,
          "refFeatureType" : 1001,
          "refId" : "",
          "content": "左侧车道边界"
        })
        data.push({
          "featureId" : this.selectedId,
          "featureType" : this.selectFeatures.layerType,
          "refFeatureId" :  this.selectFeatures.rightBoundaryId,
          "refFeatureType" : 1001,
          "refId" : "",
          "content": "右侧车道边界"
        })
    }
    this.featureList = data;
    this.loading = false;

    this.selectedLayerType = this.selectFeatures.layerType;
    this.selectFeaRealtion = layers.find((item: any) => item.type == this.selectFeatures.layerType)?.relation;
  }

  // 删除
  delRelation(index: number) {
    let removeFeature = this.featureList[index];
    let params = {
      id: removeFeature['refId'],
      featureRefType: removeFeature['dellteRefType']
    };
    this.loading = true;
    delFeatureRef(params).then(async (res: any) => {
      this.$message.success('删除关联关系成功');
      this.featureList.splice(index, 1);
      this.highlightFeatureHandle(this.selectFeatures.id, this.selectFeatures.layerType);
    }).finally(() => {
      this.loading = false;
    })

  }
  
  addRelationFeature() {
    (this as any).$refs.AddRelationInfoRef.showModal();
  }

  highlightFeatureHandle(id: string, type: string) {
    eventBus.$emit('featureHeightById', { id, type });
  }

  @Emit('selectedMap')
  selectedMap() {}

  @Emit('removeSelectMap')
  removeSelectMap() {}

}
