import { Collection, Feature } from "ol";

import { Vector, VectorImage } from "ol/layer";
import VectorSource from "ol/source/Vector";
import BaseLayer from 'ol/layer/Base';
import LayerGroup from "ol/layer/Group";

import Interaction, { InteractionOnSignature } from "ol/interaction/Interaction";

import { unByKey } from "ol/Observable";
import { EventsKey } from "ol/events";
import Event from "ol/events/Event"

type VectorLayer = Vector<any> | VectorImage<any>;

type UndoRedoOnSignature<Return> = InteractionOnSignature<EventsKey> & (
    (
        type: 'featureChanged' | 'addfeature' | 'removefeature' | ('featureChanged' | 'addfeature' | 'removefeature')[],
        listener: (event: any) => any
    ) => Return
)

interface UndoRedoOptions {
    filter?: (sources: VectorSource<any>, layer: VectorLayer) => boolean;
}

export class UndoRedoEvent extends Event {
    feature?: Feature<any>;
    action?: any;

    constructor(type: string, options: { feature?: Feature<any>, action?: any }) {
        super(type);

        if (options.feature) {
            this.feature = options.feature
        }

        if (options.action) {
            this.action = options.action
        }
    }
}

export default class UndoRedo extends Interaction {

    private readonly _undoStack: any[] = []
    private readonly _redoStack: any[] = []
    private readonly _defs: any = {};
    private readonly _filter: ((sources: VectorSource<any>, layer: VectorLayer) => boolean);
    private _block: number = 0;
    private _record: boolean = true;
    private _mapListener?: EventsKey[];
    private _sourceListener!: EventsKey[];
    private _interactionListener!: EventsKey[];

    declare public on: UndoRedoOnSignature<EventsKey>;

    constructor(options: UndoRedoOptions) {
        super({
            handleEvent: () => true
        })

        this._filter = options.filter || (() => true);
    }

    setMap(map: any) {
        if (this._mapListener) {
            this._mapListener.forEach((event) => unByKey(event));
        }
        this._mapListener = [];
        super.setMap(map);

        if (map) {
            this._mapListener.push(map.on('undoblockstart', this.blockStart.bind(this)))
            this._mapListener.push(map.on('undoblockend', this.blockEnd.bind(this)))
        }

        this.watchSources();
        this.watchInteractions();
    }

    define(action: string, undoFn: Function, redoFn: Function) {
        this._defs['_' + action] = {
            undo: undoFn,
            redo: redoFn
        }
    }

    push(action: string, prop: any) {
        if (this._defs['_' + action]) {
            this._undoStack.push({
                type: '_' + action,
                prop
            })
            return true
        } else {
            return false
        }
    }

    setActive(active: boolean): void {
        super.setActive(active);
        this._record = active;
    }

    private getVectorLayers(layers: BaseLayer[] | Collection<BaseLayer>, init: VectorLayer[] = []): VectorLayer[] {
        layers.forEach((layer) => {
            if ((layer instanceof Vector || layer instanceof VectorImage) && this._filter(layer.getSource(), layer)) {
                init.push(layer);
            } else if (layer instanceof LayerGroup) {
                this.getVectorLayers(layer.getLayers(), init);
            }
        });
        return init;
    }

    private watchSources() {
        const map: any = this.getMap();
        if (this._sourceListener) {
            this._sourceListener.forEach((event) => unByKey(event))
        }
        this._sourceListener = [];

        if (map) {
            const vectors = this.getVectorLayers(map.getLayers());
            vectors.forEach((layer: VectorLayer) => {
                const source: any = layer.getSource();
                if (this._filter(source, layer)) {
                    this._sourceListener.push(source.on(['addfeature', 'removefeature'], this.onAddRemove.bind(this)))
                    this._sourceListener.push(source.on('clearstart', this.blockStart.bind(this)))
                    this._sourceListener.push(source.on('clearend', this.blockEnd.bind(this)))
                }
            });
            this._sourceListener.push(map.getLayers().on(['add', 'remove'], this.watchSources.bind(this)))
        }
    }

    private watchInteractions() {
        const map: any = this.getMap();
        if (this._interactionListener) {
            this._interactionListener.forEach((event) => unByKey(event))
        }
        this._interactionListener = [];

        if (map) {
            map.getInteractions().forEach((inter: any) => {
                this._interactionListener.push(inter.on([
                    'setattributestart',
                    'modifystart',
                    'modifyend',
                    'modifyPointsStart',
                    'modifyPointsEnd',
                    'movepointstart',
                    'movepointend',
                    'rotatestart',
                    'rotateend',
                    'translatestart',
                    'translateend',
                    'scalestart',
                    'scaleend',
                    'deletestart',
                    'deleteend',
                    'beforesplit',
                    'aftersplit',
                    'beforeIntersect',
                    'afterIntersect',
                    'drawPolygonStart',
                    'drawPolygonEnd'
                ], this.onInteraction.bind(this)));
            });

            this._interactionListener.push(map.getInteractions().on(['add', 'remove'], this.watchInteractions.bind(this)));
        }

    }

    private onAddRemove(event: any) {
        if (this._record) {
            this._undoStack.push({
                type: event.type,
                source: event.target,
                feature: event.feature
            });
            if (event.type == 'addfeature') {
                event.feature.unset('id');
            }
            this.dispatchEvent(new UndoRedoEvent(event.type, { feature: event.feature }));
            this._redoStack.length = 0;
        }
    }

    private onInteraction(event: any) {
        if (event.type == 'setattributestart') {
            this.setAttributeStart(event);
        }
        if (['rotatestart', 'translatestart', 'scalestart', 'beforeIntersect', 'movepointstart', 'modifystart', 'modifyPointsStart'].includes(event.type)) {
            this.changeFeature(event);
        }
        if (['rotateend', 'translateend', 'scaleend', 'afterIntersect', 'movepointend', 'modifyend', 'modifyPointsEnd'].includes(event.type)) {
            this.featureChanged(event);
        }
        if (['beforesplit', 'deletestart', 'drawPolygonStart'].includes(event.type)) {
            this.blockStart();
        }
        if (['aftersplit', 'deleteend', 'drawPolygonEnd'].includes(event.type)) {
            this.blockEnd();
        }
    }

    private setAttributeStart(event: any) {
        this.blockStart();
        const newp = Object.assign({}, event.properties);
        event.features.forEach((f: Feature<any>) => {
            const oldP: any = {};
            for (const p in newp) {
                oldP[p] = f.get(p);
            }
            this._undoStack.push({
                type: 'changeattribute',
                feature: f,
                newProperties: newp,
                oldProperties: oldP
            })
        });
        this.blockEnd();
    }

    private changeFeature(event: any) {
        this.blockStart();
        event.features.forEach((feature: Feature<any>) => {
            this._undoStack.push({
                type: 'changefeature',
                feature: feature,
                oldFeature: feature.clone()
            })
        })

        this.blockEnd();
    }

    private featureChanged(event: any) {
        event.features.forEach((feature: Feature<any>) => {
            this.dispatchEvent(new UndoRedoEvent('featureChanged', { feature: feature }))
        })
    }

    blockStart() {
        this._undoStack.push({ type: 'blockstart' });
        this._redoStack.length = 0;
    }

    blockEnd() {
        this._undoStack.push({ type: 'blockend' })
    }

    private handleDo(event: any, undo: boolean) {
        if (!this.getActive()) {
            return
        }

        this._record = false;
        switch (event.type) {
            case 'addfeature': {
                if (undo) {
                    event.source.removeFeature(event.feature)
                } else {
                    event.source.addFeature(event.feature)
                }
                break;
            }
            case 'removefeature': {
                if (undo) {
                    event.source.addFeature(event.feature)
                } else {
                    event.source.removeFeature(event.feature)
                }
                break;
            }
            case 'changefeature': {
                const geom = event.feature.getGeometry();
                event.feature.setGeometry(event.oldFeature.getGeometry());
                event.oldFeature.setGeometry(geom)
                break;
            }
            case 'changeattribute': {
                const newp = event.newProperties;
                const oldp = event.oldProperties;
                for (const p in oldp) {
                    if (oldp == undefined) {
                        event.feature.unset(p);
                    } else {
                        event.feature.set(p, oldp[p])
                    }
                }
                event.oldProperties = newp;
                event.newProperties = oldp;
                break;
            }
            case 'blockstart': {
                this._block += undo ? -1 : 1;
                break;
            }
            case 'blockend': {
                this._block += undo ? 1 : -1;
                break;
            }
            default: {
                if (this._defs[event.type]) {
                    if (undo) {
                        this._defs[event.type].undo(event.prop);
                    } else {
                        this._defs[event.type].redo(event.prop);
                    }
                } else {
                    console.warn('[UndoRedoInteraction]:' + event.type.substr(1) + 'is not defined')
                }
            }
        }

        if (this._block < 0) {
            this._block = 0;
        }
        if (this._block) {
            if (undo) {
                this.undo();
            } else {
                this.redo();
            }
        }
        this._record = true;

        this.dispatchEvent(new UndoRedoEvent(undo ? 'undo' : 'redo', { action: event }))
    }

    undo() {
        const e = this._undoStack.pop();
        if (!e) {
            return
        }
        this._redoStack.push(e);
        this.handleDo(e, true);
    }

    redo() {
        const e = this._redoStack.pop();
        if (!e) {
            return
        }
        this._undoStack.push(e);
        this.handleDo(e, false);
    }

    clear() {
        this._undoStack.length = 0;
        this._redoStack.length = 0;
    }

    hasUndo() {
        return this._undoStack.length;
    }

    hasRedo() {
        return this._redoStack.length;
    }

    getDeletedFeatures(source: VectorSource<any>) {
        return this._undoStack.filter(e => e.type == 'removefeature' && e.source == source && e.feature.get('id')).map(e => e.feature)
    }

    getIncrementFeatures(): Map<number, any> {
        const map: any = this.getMap();
        const removed = this._undoStack.filter(e => e.type == 'removefeature').map(e => e.feature);
        let modified = this._undoStack.filter(e => e.type == 'changefeature' || e.type == 'changeattribute').map(e => e.feature);
        const custom = this._undoStack.filter(e => this._defs[e.type] && e.prop).map(e => e.prop.feature);
        if (custom && custom.length > 0) {
            modified = modified.concat(custom);
        }
        const vectors = this.getVectorLayers(map.getLayers());
        const incrementMap = new Map<number, any>();

        function setIncrementMap(vector: VectorLayer, cache: any, operator: string) {
            const key = vector.getSource().get('layerType');
            if (incrementMap.has(key)) {
                incrementMap.get(key)[operator].add(cache.feature);
            } else {
                const add = operator == 'add' ? new Set([cache.feature]) : new Set();
                const remove = operator == 'del' ? new Set([cache.feature]) : new Set();
                const modify = operator == 'edit' ? new Set([cache.feature]) : new Set();
                incrementMap.set(key, { 'add': add, 'del': remove, 'edit': modify });
            }
        }

        for (const vector of vectors) {
            this._undoStack.forEach(e => {
                // 数据id
                if (e.type == 'removefeature' && e.source == vector.getSource() && e.feature.get('id')) {
                    setIncrementMap(vector, e, 'del');
                }
                if (e.type == 'addfeature' && e.source == vector.getSource() && !removed.includes(e.feature) && !modified.includes(e.feature)) {
                    setIncrementMap(vector, e, 'add');
                }
                if ((e.type == 'changefeature' || e.type == 'changeattribute') && vector.getSource().hasFeature(e.feature)) {
                    setIncrementMap(vector, e, 'edit');
                }
                if ((this._defs[e.type]) && e.prop && vector.getSource().hasFeature(e.prop.feature)) {
                    setIncrementMap(vector, e.prop, 'edit')
                }
            })
        }

        return incrementMap
    }

    clearIncrementFeatures() {
        this.clear();
    }

    getNotSavedFeatureSize(): number | undefined {
        return this.getIncrementFeatures().size || 0;
    }
}
