import { Map as olMap, MapBrowserEvent } from "ol";
import RenderBox from "ol/render/Box";
import Pointer from "ol/interaction/Pointer";
import { mouseActionButton } from "ol/events/condition";



type BoxEndCondition = (_: MapBrowserEvent<any>, startPixel: number[] | null, endPixel: number[]) => boolean | undefined;


interface BoxPointerOptions {
    className?: string;
    minArea?: number;
    boxEndCondition?: BoxEndCondition;
    condition?: (_: MapBrowserEvent<any>) => boolean;
}

export default abstract class BoxPointer extends Pointer {
    private readonly _minArea: number;
    private _startPixel: number[] | null;

    protected readonly _box: any;
    protected readonly _boxEndCondition: BoxEndCondition;
    protected _condition: (_: MapBrowserEvent<any>) => boolean;


    protected constructor(options: BoxPointerOptions) {
        super();

        this._startPixel = null;
        this._minArea = options.minArea || 64;

        this._box = new RenderBox(options.className || 'ol-dragbox');
        this._boxEndCondition = options.boxEndCondition || this.defaultBoxEndCondition;
        this._condition = options.condition ? options.condition : mouseActionButton;

    }

    setMap(map: olMap) {
        if (!map) {
            this._box?.setMap(null);
        }
        super.setMap(map);
    }

    private defaultBoxEndCondition(evt: MapBrowserEvent<any>, startPixel: number[] | null, endPixel: number[]) {
        if (startPixel !== null) {
            const width = endPixel[0] - startPixel[0]
            const height = endPixel[1] - startPixel[1]
            return width * width + height * height >= this._minArea;
        }
    }

    getGeometry() {
        return this._box?.getGeometry();
    }

    protected abstract onBoxEnd(_: MapBrowserEvent<any>): void;


    protected handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        if (this._box?.map_) {
            this._box?.setPixels(this._startPixel!, mapBrowserEvent.pixel);
        }
    }

    protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (this._condition(mapBrowserEvent)) {
            this._startPixel = mapBrowserEvent.pixel;
            this._box?.setMap(mapBrowserEvent.map);
            this._box?.setPixels(this._startPixel, this._startPixel);
            return true;
        }
        return false;
    }

    protected handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (this._box?.map_) {
            this._box?.setMap(null);
            const box = this._boxEndCondition(mapBrowserEvent, this._startPixel, mapBrowserEvent.pixel);
            if (box) {
                this.onBoxEnd(mapBrowserEvent);
            }
        }
        return false;
    }
}