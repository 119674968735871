
import { Vue, Component, Watch } from "vue-property-decorator";
import { layersTitle } from "@/config/layerConfig";
import axios from "axios";
import { state } from "@/store/index";
import { getDownloadFileApi } from "@/config/api";
interface IFormState {
    name: string;
    bias: number | undefined;
    num: number | undefined;
    other: string | undefined;
    featureCodes: string[];
}
const layersTypeNmae = Object.keys(layersTitle);

@Component
export default class PosPopup extends Vue {
    isShowDataExportPop: boolean = false;
    layersType: any = layersTitle;
    formData: IFormState = {
        name: "",
        bias: undefined,
        num: undefined,
        other: "",
        featureCodes: layersTypeNmae,
    };
    checked: boolean = true;
    confirmLoading: boolean = false;
    indeterminate: boolean = false;
    checkAll: boolean = true;

    @Watch("formData.featureCodes")
    featureCodesChange(val: string[]) {
        this.indeterminate = !!val.length && val.length < layersTypeNmae.length;
        this.checkAll = val.length === layersTypeNmae.length;
    }

    show() {
        this.isShowDataExportPop = true;
    }
    async handleOk() {
        let obj = {
            taskIds: state.taskId,
            featureCodes: this.formData.featureCodes.join(","),
        };

        if (!this.formData.name) {
            this.$message.warn('请输入地图名称')
            return
        }
        let data = Object.assign({}, this.formData, obj);
        this.confirmLoading = true;

        const res = await getDownloadFileApi(data);
        window.open(`/api/v1/feature/downloadFile?filePath=${res}`);
        this.isShowDataExportPop = false;
        this.confirmLoading = false;
    }
    handleCancel() {
        this.isShowDataExportPop = false;
        this.confirmLoading = false;
    }
    onCheckAllChange(e: any) {
        this.formData.featureCodes = e.target.checked ? layersTypeNmae : [];
        this.indeterminate = false;
    }
}
