import ModifyBase, { ModifyEvent, Options as ModifyOptions } from "ol/interaction/Modify";

import { MapBrowserEvent } from "ol";
import { fromUserCoordinate, getUserProjection } from "ol/proj";
import { getUid } from "ol/util";

import { distance as coordsDistance } from "ol/coordinate";
import PointerInteraction from "ol/interaction/Pointer";


export default class Modify extends ModifyBase {
    constructor(options: ModifyOptions) {
        super(options);
    }


    protected handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        const _this: any = this;
        _this.ignoreNextSingleClick_ = false;
        _this.willModifyFeatures_(mapBrowserEvent, _this.dragSegments_);

        const coords = [
            mapBrowserEvent.coordinate[0] + this.delta_[0],
            mapBrowserEvent.coordinate[1] + this.delta_[1],
        ]

        if (mapBrowserEvent.coordinate[2]) {
            coords.push(mapBrowserEvent.coordinate[2]);
        }

        const features: any[] = [];
        const geometrys: any[] = [];
        for (let i = 0, ii = _this.dragSegments_.length; i < ii; ++i) {
            const segmentData = _this.dragSegments_[i][0];
            if (!features.includes(segmentData.feature)) {
                features.push(segmentData.feature)
            }

            const geometry = segmentData.geometry;
            if (!geometrys.includes(geometry)) {
                geometrys.push(geometry);
            }

            const depth = segmentData.depth;
            const segment = segmentData.segment;

            const index = _this.dragSegments_[i][1];
            let coordinates = [];

            const stride = geometry.getStride();
            while (coords.length < stride) {
                coords.push(segment[index][coords.length]);
            }


            const type = geometry.getType();
            if (type == 'Point') {
                coordinates = coords;
                segment[0] = coords;
                segment[1] = coords;
            } else if (type == 'MultiPoint') {
                coordinates = geometry.getCoordinates();
                coordinates[segmentData.index] = coords;
                segment[0] = coords;
                segment[1] = coords;
            } else if (type == 'LineString') {
                coordinates = geometry.getCoordinates();
                coordinates[segmentData.index + index] = coords;
                segment[index] = coords;
            } else if (['MultiLineString', 'Polygon'].includes(type)) {
                coordinates = geometry.getCoordinates();
                coordinates[depth[0]][segmentData.index + index] = coords;
                segment[index] = coords;
            } else if (type == 'MultiPolygon') {
                coordinates = geometry.getCoordinates();
                coordinates[depth[1]][depth[0]][segmentData.index + index] = coords;
                segment[index] = coords;
            } else if (type == 'Circle') {
                segment[0] = coords;
                segment[1] = coords;

                if (segmentData.index === 0) {
                    _this.changingFeature_ = true;
                    geometry.setCenter(coords);
                    _this.changingFeature_ = false;
                } else {
                    _this.changingFeature_ = true;
                    const proj = mapBrowserEvent.map.getView().getProjection();
                    const coord1 = fromUserCoordinate(geometry.getCenter(), proj);
                    const coord2 = fromUserCoordinate(coords, proj);
                    const r = coordsDistance(coord1, coord2);

                    const userProj = getUserProjection();
                    if (userProj) {
                        let geom = geometry.clone();
                        geom = geom.transform(userProj, proj);
                        geom.setRadius(r);
                        const NR = geom.transform(proj, userProj).getRadius();
                        geometry.setRadius(NR);
                    } else {
                        geometry.setRadius(r);
                    }
                    _this.changingFeature_ = false;
                }
            }
            if (coordinates) {
                _this.setGeometryCoordinates_(geometry, coordinates);
            }
        }
        _this.createOrUpdateVertexFeature_(coords, features, geometrys);
    }

    handleEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        const _this: any = this;
        if (!mapBrowserEvent.originalEvent) {
            return true;
        }
        _this.lastPointerEvent_ = mapBrowserEvent;
        let handled = null;
        if (!mapBrowserEvent.map.getView().getInteracting() && mapBrowserEvent.type == 'pointermove' && this.handlingDownUpSequence) {
            _this.handlePointerMove_(mapBrowserEvent);
        }
        if (_this.deleteCondition_(mapBrowserEvent)) {
            if (mapBrowserEvent.type !== 'singleclick' || !_this.ignoreNextSingleClick_) {
                handled = this.isRemovePoint();
            } else {
                handled = true;
            }
        }

        if (mapBrowserEvent.type == 'singleclick') {
            _this.ignoreNextSingleClick_ = false;
        }

        return PointerInteraction.prototype.handleEvent.call(this, mapBrowserEvent) && !handled;
    }

    isRemovePoint(): boolean {
        const _this: any = this;
        if (_this.lastPointerEvent_ && _this.lastPointerEvent_.type !== 'pointerdrag') {
            const event = _this.lastPointerEvent_;
            _this.willModifyFeatures_(event, _this.dragSegments_);
            const removed = _this.removeVertex_();
            if (_this.featuresBeingModified_) {
                this.dispatchEvent(new ModifyEvent("modifyend", _this.featuresBeingModified_, event));
            }
            _this.featuresBeingModified_ = null;
            return removed;
        }
        return false;
    }

    getModifedFeatures() {
        const _this: any = this;
        const featuresById: Record<any, any> = {};
        _this.dragSegments_.forEach((item: any[]) => {
            const feature = item[0].feature;
            featuresById[getUid(feature)] = feature;
        });
        const features = [];
        for (const i in featuresById) {
            features.push(featuresById[i])
        }
        return features;
    }
}