import Vue from 'vue'
import App from './App.vue'
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import "./lazy_lib"
import "@/components/SvgIcon/IconUntil"
import "@/assets/css/ol-tooltip.less"
import "@/assets/css/mode_Form.less"
import router from '@/router/router'; // 导入你的路由文件

Vue.config.productionTip = false

new Vue({
  router, // 将路由实例添加到 Vue 实例的选项中
  render: h => h(App),
}).$mount('#app')
