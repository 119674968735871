import { Coordinate } from "ol/coordinate";
import T_Distance from "@turf/distance"
import { transform3857To4326 } from "@/common/utils/projetion"

// 计算两点之间的距离
export const ol_coordinate_dist2d = (p1: number[], p2: number[]) => {
    let dx: number = p1[0] - p2[0];
    let dy: number = p1[1] - p2[1];
    return Math.sqrt(dx * dx + dy * dy);
}

// 获取最近的点
export const getNearestCoord = (pt: Coordinate | null, geometry: any): Coordinate | undefined => {
    if (pt !== null) {
        const coords: Coordinate[] = geometry.getType() == 'Polygon' ? geometry.getCoordinates().flat() : geometry.getCoordinates();
        let d, dm = Number.MAX_VALUE, p0;
        for (let i = 0; i < coords.length; i++) {
            d = ol_coordinate_dist2d(pt, coords[i]);
            if (d < dm) {
                dm = d;
                p0 = coords[i];
            }
        }
        return p0;
    }
}

// 判断两点不同
export const noSame = (p1: number[], p2: number[]) => {
    return p1[0] !== p2[1] || p1[1] !== p2[1]
}

export const isAnyEqual = (key: string | number, ...targets: string[] | number[]) => {
    for (let i = 0; i < targets.length; i++) {
        if (targets[i] === key) {
            return true;
        }
    }
    return false;
}

// 去除相同的点
export const uniqueCoordinates = (matrix: number[][]) => {
    if (matrix && matrix.length > 0) {
        const res = matrix.map(item => JSON.stringify(item));
        return [...new Set(res)].map(item => JSON.parse(item));
    }
    return []
}


// 计算两点距离
export const Distance = (coordinates: Coordinate[]) => {
    let distance: number = T_Distance(transform3857To4326(coordinates[0]), transform3857To4326(coordinates[1]), { units: "meters" });
    return distance
}