import { MapBrowserEvent, Map as olMap, Feature } from "ol";
import RenderFeature from "ol/render/Feature";
import Event from "ol/events/Event";
import { Point } from "ol/geom";
import { altKeyOnly, shiftKeyOnly } from "ol/events/condition";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Style } from "ol/style";
import StyleBuilder from "@/common/extend/StyleBuilder"
import BoxPointer from "@/common/Interactions/BoxPointer";


export class ModifyPointsEvent extends Event {
    features?: Feature<any>[];

    constructor(type: string, options: { features?: Feature<any>[] }) {
        super(type);
        if (options.features) {
            this.features = options.features
        }
    }
}

export default class ModifyPoints extends BoxPointer {
    private readonly _hoverStyle: Style | Style[] | ((_: Feature<any> | RenderFeature) => Style | Style[]);
    private readonly _overlayLayer: VectorLayer<VectorSource<any>>;

    private _sources: VectorSource<any>[];
    private _selectedFeatures: any[];

    private _arcs: any[];
    private _startCoordinate: any[];
    private _moveSelected: Feature<any>[];
    private _bound: any | undefined;

    constructor(options: any) {
        if (!options) {
            options = {};
        }
        super(options);

        this._sources = options.sources ? (options.sources instanceof Array) ? options.sources : [options.sources] : [];
        this._arcs = [];
        this._moveSelected = [];
        this._startCoordinate = [];
        this._selectedFeatures = [];
        this._bound = options.bound || undefined;


        this._hoverStyle = options.hoverStyle || StyleBuilder.SelectVertices(true);
        this._overlayLayer = new VectorLayer({
            source: new VectorSource({ useSpatialIndex: false }),
            style: StyleBuilder.SelectVertices(true)
        })
    }

    setMap(map: olMap): void {
        if (this.getMap()) {
            this.getMap()?.removeLayer(this._overlayLayer);
        }
        super.setMap(map);
        this._overlayLayer.setMap(map);
    }

    protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (!shiftKeyOnly(mapBrowserEvent) && !altKeyOnly(mapBrowserEvent)) {
            this._arcs = [];
            this.clearPoints();
            this.clearVertex();
        }
        if (!altKeyOnly(mapBrowserEvent)) {
            return super.handleDownEvent(mapBrowserEvent);
        } else {
            if (!this._arcs.length) {
                return true
            }
            this._startCoordinate = mapBrowserEvent.coordinate;
            this.dispatchEvent(new ModifyPointsEvent("modifyPointsStart", { features: this._selectedFeatures }));
            return true;
        }
    }

    protected handleDragEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        if (!this._arcs) {
            return;
        }
        if (altKeyOnly(mapBrowserEvent) && mapBrowserEvent.pixel) {
            const offset = [
                mapBrowserEvent.coordinate[0] - this._startCoordinate[0],
                mapBrowserEvent.coordinate[1] - this._startCoordinate[1]
            ];

            this.clearVertex();

            this._arcs.forEach((item: any) => {
                if (!['LineString', 'Polygon'].includes(item.type)) return true;

                item.indexs.forEach((index: number) => {
                    item.coords[index][0] = item.coords[index][0] + offset[0]
                    item.coords[index][1] = item.coords[index][1] + offset[1]
                    
                    const point = new Feature(new Point([item.coords[index][0], item.coords[index][1]]))
                    this._overlayLayer.getSource()?.addFeature(point);
                })
                this._startCoordinate = mapBrowserEvent.coordinate;
            })

            this._arcs.forEach((item: any) => {
                if (item.type == 'LineString') {
                    item.geom.setCoordinates(item.coords);
                } else if (item.type == 'Polygon') {
                    item.geom.setCoordinates([item.coords]);
                }
            })

            if (!this._arcs.length) {
                return;
            }
        }
        super.handleDragEvent(mapBrowserEvent);
    }

    protected handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        super.handleUpEvent(mapBrowserEvent);
        if (altKeyOnly(mapBrowserEvent)) {
            this.dispatchEvent(new ModifyPointsEvent('modifyPointsEnd', { features: this._selectedFeatures }));
            this.clearPoints();
            this.clearVertex();
            this._selectedFeatures = [];
            this._arcs = [];
        }
        return false;
    }

    private _getVisibleSource(source: VectorSource<any>): any {
        let vector = null;
        const layers = this.getMap()?.getLayers().getArray();
        layers?.forEach((layer: any) => {
            if (layer.getVisible() && (layer.getSource() == source)) {
                vector = layer.getSource();
            }
        })
        return vector;
    }

    protected onBoxEnd(_: MapBrowserEvent<any>): void {
        const extent = this.getGeometry().getExtent();
        this._sources.forEach((vector: VectorSource<any>) => {
            const source: VectorSource<any> = this._getVisibleSource(vector);
            if (source) {
                source.forEachFeatureIntersectingExtent(extent, (feature: any) => {
                    let coords = [];

                    feature.setStyle(this._hoverStyle);
                    this._moveSelected.push(feature);
                    this._selectedFeatures.push(feature);

                    const geometry = feature.getGeometry();
                    const type = geometry.getType();
                    const ol_uid = geometry.ol_uid;
                    let coordinates = feature.getGeometry().getCoordinates();

                    if (type == 'LineString') {
                        coords = coordinates;
                    } else if (type == 'Polygon') {
                        coords = coordinates.flat();
                    }

                    let selectedIndex: any[] = [];
                    coords.forEach((coord: number[], index: number) => {
                        if (this.getGeometry().intersectsCoordinate(coord)) {
                            const point = new Feature(new Point(coord));
                            this._overlayLayer.getSource()?.addFeature(point);
                            selectedIndex.push(index);
                        }
                    });

                    for (let i = 0; i < this._arcs.length; i++) {
                        if (this._arcs[i].geom.ol_uid == ol_uid) {
                            selectedIndex = this.getArrDifference(selectedIndex, this._arcs[i].indexs);
                        }
                    }

                    const data = {
                        geom: geometry,
                        coords: coords,
                        indexs: selectedIndex,
                        type: type
                    }
                    this._arcs.push(data)
                })
            }

        })
    }

    private getArrDifference(arr1: number[], arr2: number[]): number[] {
        return [...new Set(arr1.concat(arr2))]
    }

    setSources(sources: VectorSource<any>[] | VectorSource<any>): void {
        this._sources = sources instanceof Array ? sources : [sources];
    }

    clearVertex() {
        this._overlayLayer.getSource()?.clear();
    }

    clearPoints() {
        this._moveSelected.forEach(f => f.setStyle(undefined));
        this._moveSelected = []
    }
}