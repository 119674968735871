import { Feature, Collection, MapBrowserEvent } from "ol";
import RenderFeature from "ol/render/Feature";

import { EventsKey } from "ol/events";
import DragBox, { DragBoxOnSignature, Options } from "ol/interaction/DragBox";

import { Layer } from "ol/layer";
import BaseLayer from "ol/layer/Base";

import VectorSource from "ol/source/Vector";

import Event from "ol/events/Event";

interface DragBoxOptions extends Options {
    features?: Collection<Feature<any>>;
    sources?: VectorSource<any>[];
    filter?: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;
}

class DragBoxSelectEvent extends Event {
    selectedFeatures: Collection<Feature<any>>;

    constructor(type: string, selectedFeatures: Collection<Feature<any>>) {
        super(type);
        this.selectedFeatures = selectedFeatures;
    }
}

type DragSelectOn<Return> = DragBoxOnSignature<EventsKey>
    & ((type: 'boxSelect' | ('boxSelect')[], listener: (event: DragBoxSelectEvent) => any) => Return)
    & ((type: 'clearFeatures' | ('clearFeatures')[], listener: (event: DragBoxSelectEvent) => any) => Return)

export default class Select extends DragBox {
    private _sources: VectorSource<any>[];
    private readonly _features: Collection<Feature<any>>;

    private readonly _filter: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;

    declare on: DragSelectOn<EventsKey>

    constructor(options: DragBoxOptions) {
        super(options);

        this._sources = options.sources || [];
        this._features = options.features || new Collection();
        this._filter = options.filter || (() => true);
    }

    get selectedFeatures(): Collection<Feature<any>> {
        return this._features;
    }

    setSources(sources: VectorSource<any>[]) {
        this._sources = sources;
    }

    onBoxEnd(): void {
        this.findSelectedFeatures();
        this.dispatchEvent(new DragBoxSelectEvent('boxSelect', this._features));
    }

    protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (super.handleDownEvent(mapBrowserEvent)) {
            if (!mapBrowserEvent.originalEvent.shiftKey) {
                this._features.clear();
                this.dispatchEvent(new DragBoxSelectEvent('clearFeatures', this._features));
            }
            return true;
        }
        return false;
    }

    findSelectedFeatures(): void {
        const rotate: any = this.getMap()?.getView().getResolution();
        const r = rotate % (Math.PI / 2) !== 0;
        const features = r ? new Collection<Feature<any>>() : this._features;

        this.findFeaturesForSource(features);

        if (r) {
            const a = [0, 0];
            const geom = this.getGeometry().clone();
            geom?.rotate(-rotate, a);
            const extent = geom.getExtent();
            features?.forEach((feature: Feature<any>) => {
                const geometry = feature.getGeometry().clone();
                geometry?.rotate(-rotate, a);
                if (geometry.intersectsExtent(extent)) {
                    if (!this.isInCludes(feature)) {
                        this._features.push(feature);
                    } else {
                        this._features.remove(feature);
                        feature.setStyle(undefined);
                    }
                }
            })
        }

    }

    findFeaturesForSource(features: Collection<Feature<any>>): void {
        const extent = this.getGeometry().getExtent();
        this.getMap()?.getLayers().getArray().forEach((layer: BaseLayer) => {
            if (layer instanceof Layer && this._filter(new Feature(), layer) && this._sources.includes(layer.getSource())) {
                layer.getSource().forEachFeatureIntersectingExtent(extent, (feature: Feature<any>) => {
                    if (!features.getArray().includes(feature)) {
                        features.push(feature)
                    } else {
                        features.remove(feature);
                        feature.setStyle(undefined);
                    }
                })
            }
        })
    }

    isInCludes(f: Feature<any>): boolean {
        return this._features.getArray().includes(f)
    }

    addFeature(feature: any) {
        if (!this.isInCludes(feature)) {
            this._features.push(feature);
        }
    }
}