import layersStyleType from "@/config/LayerStyle"
import { layersTitle as LayersTitle } from "@/config/layerConfig"

export default class LayerStyleUtils {
    _layers: any;
    _layersStyleType: any;

    constructor() {
        this._layers = LayersTitle;  // [type: string]: 
        this._layersStyleType = layersStyleType  // [layerKey: string]: Function
    }


    // 配置颜色
    getConfigStyle(layerType: any, feature: any, zoom: any, map: any) {
        const layerName = this._layers[layerType];
        const layerStyle = this._layersStyleType[layerName];
        
        // 还需要一个初始颜色
        if (layerStyle) {
            if (layerType == 1001) {
                const styleKey: any = this.getStyleKey(feature)
                
                if (styleKey) {
                    const styleFn = layerStyle[styleKey];
                    if (styleFn instanceof Function) {
                       return styleFn(zoom, feature, map);
                    } else {
                       return this._layersStyleType['default']()
                    }
                }
            } else {
                return layerStyle(feature, zoom)
            }
        }
    }

    getStyleKey(feature: any) {
        const colorKeys = ['lineType', 'color'];
        if (colorKeys) {
            return colorKeys.map(key => feature.get(key)).join('-')
        }
    }
}