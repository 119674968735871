// @ts-nocheck
import Vue from 'vue'

export const state = Vue.observable({
	taskId : '',
	zlevel : 0,
	tilesList : [],
	isSelectMap: false,
	maxZLevel : 0,

	data: {
		id: "1",
		name: "this is name"
	},
	emojiString: 'Hello World!'
})

// 定义data对象属性约束
export interface Idata {
    id: string,
    name: string
}


/**
 * 修改数据的公共方法
 * @param proName 数据名称
 * @param value  数据对应的值
 */
export function setState<T>(proName:string, value: T): void {
	// console.log(`setState ${proName}:`,value)
	state[proName] = value
}

// 修改taskID
export const setTaskId = (taskId: string): void => {
	// console.log(`setState:`,taskId)
    state.taskId = taskId
}
// 修改data
export const setData = (data: Idata): void => {
	// console.log(`setData:`,data)
    state.data = data
}

/*
// 页面引用
import {state,Idata,setState,setTaskId,setData} from "@/store/index";

// 无需监听引入
state.data

// 监听引入
get stateData():Idata{
	return state.data
}

// 使用公共方法修改数据
setState("data",{id:"333",name:"3333"});

// 使用特有方法修改特定数据
setData({id:"222",name:"22222"});



 */



