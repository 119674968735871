
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import {
    transform3857To4326,
    transform4326To3857,
} from "@/common/utils/projetion";

@Component
export default class LocalCoords extends Vue {
    @Prop() mapZoomLevel?: any;
    @Prop() localCoordinate?: any;

    is: boolean = true;
    localCoords: any = '';
    CoordinateSystem: any = "3857";

    @Watch("localCoordinate", { deep: true, immediate: true })
    change(val: any) {
        if (this.is) {
            if (this.CoordinateSystem == "3857") {
                this.localCoords = val.join(',');
            } else {
                this.localCoords = transform3857To4326(val).join(',');
            }
        }
    }

    systemChange(val: any) {
        if (val == "3857") {
            this.localCoords = this.localCoordinate.join(',')
        } else {
            this.localCoords = transform3857To4326(this.localCoordinate).join(',');
        }
    }

    handleFocus() {
        this.is = false;
    }
    handleBlur() {
        this.is = true;
    }

    handleOk() {
        let coords: number[] = [];
        if (this.localCoords !== '') {
            let localCoords = this.localCoords.split(',');
            if (localCoords.length == 2) {
                if (this.CoordinateSystem == "4326") {
                    coords = transform4326To3857([
                        localCoords[0],
                        localCoords[1],
                    ]);
                } else {
                    coords = localCoords.map((item: any) => Number(item));
                }
            }
            this.$emit('change', coords);
        }
    }
}
