
import {Vue, Component, Prop, Ref, Watch} from "vue-property-decorator";
import {FormModel} from "ant-design-vue";
import {checkMultiSelect} from "@/utils/validates";
import {addTag} from '@/config/api'
import {writeFeatureObject} from "@/common/utils/projetion";
import {state} from "@/store";

@Component
export default class PosPopup extends Vue {
  visible: boolean = false
  confirmLoading: boolean = false
  currentFeature: any = null
  formDate: any = {
    questDescript: ''
  }
  validatorRules: any = {
    questDescript: [
      {required: true, message: '请输入问题描述!'}
    ]
  }

  mounted() {

  }

  show(feature: any) {
    this.visible = true
    this.currentFeature = feature
    this.$nextTick(() => {
      (this.$refs.formRef as FormModel).resetFields()
    })
  }

  handleOk() {
    let formRef = this.$refs.formRef as FormModel
    formRef.validate((boolean, object) => {
      if (boolean) {
        let params = {
          geom: JSON.stringify(writeFeatureObject(this.currentFeature).geometry),
          taskId: state.taskId,
          describe: this.formDate.questDescript
        }
        addTag(params).then((res: any) => {
          this.$emit('tagHandleOk')
        })
        this.visible = false
      }
    })
  }

  handleCancel() {
    this.visible = false
    this.$emit('cancelTag', this.currentFeature)
  }

}
