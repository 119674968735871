
import { Vue, Component, Prop } from "vue-property-decorator";

interface Style {
    width?: any;
    height?: any;
    color?: any;
}

@Component
export default class SvgIcon extends Vue {
    @Prop() iconClass?: {
        type: String;
        required: true;
    };
    @Prop() color?: {
        type: String;
        reuqired: false;
    };
    @Prop() size?: [Number, String];

    @Prop() className?: {
        type: String;
        default: "";
    };

    get classes() {
        return this.className ? `svg-icon ${this.className}` : 'svg-icon';
    }

    get styles() {
        let style: Style = {};
        if (typeof this.size == "number") {
            style.width = `${this.size}px`;
            style.height = `${this.size}px`;
        } else {
            style.width = this.size;
            style.height = this.size;
        }
        if (this.color) {
            style.color = this.color;
        }
        return style;
    }

    get iconName() {
        return `#icon-${this.iconClass}`;
    }
}
