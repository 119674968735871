import {generateLaneGroupApi} from "@/config/api";

export const message = {
    saveSuccess:"保存成功",
    generateSuccess:"生成成功",
    changeFeatureProperties:"要素属性修改成功",
    inputError:"请输入要素属性",
    error500:"服务器异常"
}


export const checkErrorMessage = {
    'boundary_before_no_exist': '前车道边线不存在',
    'boundary_after_no_exist': '后车道边线不存在',
    'boundary_no_bind_lane_group': '未绑定车道组',
    'lane_before_no_exist': '前车道不存在',
    'lane_after_no_exist': '后车道不存在',
    'lane_no_bind_left_boundary': '左车道边界未绑定',
    'lane_no_bind_right_boundary': '右车道边界未绑定',
    'lane_no_bind_lane_group': '未绑定车道组',
    'traffic_light_no_bind_lane': '红绿灯未绑定车道',
    'traffic_light_no_bind_crosswalk': '红绿灯未绑定人行横道',
    'traffic_sign_no_bind_lane': '标志牌未绑定车道'
}
