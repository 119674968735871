import { Interaction } from "ol/interaction";
import { Map as olMap, Overlay, Feature } from "ol"
import { Geometry, LineString } from "ol/geom";
import { unByKey } from "ol/Observable";
import Draw from "@/common/Interactions/Draw";
import { Distance } from "@/common/utils/CommonUtil";

interface MeasureLineOptions {
    draw: Draw;
    measureMode?: string;
    isOrdinary?: boolean;
}


export default class MeasureLine extends Interaction {
    private readonly _draw: Draw;
    private readonly _overlayList: any[];
    private readonly _measureMode: string | undefined;
    private _sketch: Feature<any> | null;
    private _measureTooltipElement: any;
    private _measureTooltip: any;
    private result: string[];
    private results: string[];
    private total: number;
    private _isOrdinary?: boolean;


    constructor(options: MeasureLineOptions) {
        super();

        this._draw = options.draw;
        this._measureMode = options.measureMode;
        this._sketch = null;
        this._measureTooltipElement = null;
        this._measureTooltip = null;

        this.result = [];
        this.results = [];
        this._overlayList = [];

        this.total = 0;
        this._isOrdinary = options.isOrdinary || false;
    }


    setMap(map: olMap) {
        if (!map && this.getMap()) {
            this.clearOverlays();
        }
        super.setMap(map);

        if (this.getMap()) {
            this.bindDrawEvent();
        }
    }

    public clearOverlays() {
        this._overlayList.forEach(data => {
            this.getMap()?.removeOverlay(data);
        })
    }

    private bindDrawEvent() {
        this.createMeasureTooltip();
        let listeren: any;
        this._draw.on('drawstart', (evt: any) => {
            this._sketch = evt.feature as Feature<any>;
            let tooltipCoord = evt.coordinate;
            listeren = this._sketch.getGeometry().on('change', async (e: { target: Geometry }) => {
                const geom = e.target;
                let output: number | string = 0;
                const lineLength: any = [];
                if (geom instanceof LineString) {
                    if (this._measureMode == 'length') {
                        const coordinates = geom.clone().getCoordinates();
                        for (let i = 0; i < coordinates.length - 1; i++) {
                            const coords = Distance([coordinates[i], coordinates[i + 1]]);
                            lineLength.push(coords);
                        }
                        lineLength.forEach((item: any) => output = Number(output) + item);
                        output = this.formatLength(output);
                    }
                    tooltipCoord = geom.getLastCoordinate();
                }
                this._measureTooltip.setPosition(tooltipCoord);
                this._measureTooltipElement.innerHTML = output;
            })
        })


        this._draw.on('drawend', async () => {
            this._measureTooltipElement.className = 'ol-tooltip ol-tooltip-static'
            this._measureTooltipElement.parentNode.className = 'ol-events-none';
            this._measureTooltip.setOffset([0, -7]);

            if (this._measureMode == 'length') {
                this.showLastLengthTips();
            }

            this._sketch = null;
            this._measureTooltipElement = null;
            this.createMeasureTooltip();
            unByKey(listeren);

        })
    }

    private createMeasureTooltip() {
        if (this._measureTooltipElement) {
            this._measureTooltipElement.parentNode.removeChild(this._measureTooltipElement);
        }
        this._measureTooltipElement = document.createElement('div');
        this._measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';

        this._measureTooltip = new Overlay({
            element: this._measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        })

        this.getMap()?.addOverlay(this._measureTooltip);
        this._overlayList.push(this._measureTooltip);
    }

    private formatLength(output: any): string {
        let length = null;
        if (output > 1000) {
            length = (output / 1000).toFixed(4) + ' ' + 'Km'
        } else {
            length = parseFloat(output).toFixed(4) + ' ' + 'm'
        }
        return length;
    }

    private formatLengths(output: any): string {
        return parseFloat(output).toFixed(4);
    }

    private showLastLengthTips() {
        this._measureTooltipElement.innerHTML = '';
        this.result = [];
        this.results = [];
        this.total = 0;
        let coordinates = [];
        if (this._sketch) {
            coordinates = this._sketch?.getGeometry().clone().getCoordinates();
            for (let i = 0; i < coordinates.length - 1; i++) {
                const coords = Distance([coordinates[i], coordinates[i + 1]]);
                this.result.push(this.formatLengths(coords));
                this.results.push(this.formatLength(coords));
            }
            this.result.forEach(item => {
                this.total += Number(item.slice(0, item.length - 1));
            })
        }
        this.closeElement();
        const div = document.createElement('div');


        if (this._isOrdinary) {
            div.innerHTML = this.formatLength(this.total);
            let length = this.formatLengths(this.total);

            this.dispatchEvent({ type: 'change', target: length } as any);
        } else {
            div.innerHTML = `总长度：${this.formatLength(this.total)}`;
        }

        this._measureTooltipElement.appendChild(div);
    }

    private closeElement() {
        const close = document.createElement('a');
        close.className = 'ol-tooltip-close';
        close.innerHTML = '✖';
        this._measureTooltipElement.appendChild(close);
        const feature = this._sketch as Feature<any>;
        const overlay = this._measureTooltip;
        const map: any = this.getMap();
        close.onclick = () => {
            map.removeOverlay(overlay);
            this._overlayList.splice(this._overlayList.findIndex(item => item == overlay), 1);
            this._draw.getSource().removeFeature(feature);
        }
    }
}