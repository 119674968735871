
import {Component, Vue, Watch, Prop, InjectReactive} from "vue-property-decorator";
import {eventBus} from '@/utils/eventBus';
import { delLightGroup } from "@/config/api";

@Component
export default class CollapseList extends Vue {
  activeKey: any[] = [];
  currentClickIndex: number = 0
  lightGroupTest: any[] = []

  @Prop() lightListData: any;

  @Watch('lightListData', { immediate: true })
  lightListDataChange(newData: any[]) {
    this.lightGroupTest = newData
  }

  delTag(index: number, item: any) {
    this.lightGroupTest.splice(index, 1)
    delLightGroup(item.trafficLightGroupId).then((res: any) => {
      this.$message.success('删除灯组成功！')
    })
  }

  clickId(item: any) {
    eventBus.$emit('highLightGroup', item)
  }
}
