
import Vue from 'vue';
import Home from "@/views/home/index.vue"

export default Vue.extend({
  name: 'App',
  components: {
    Home
  }
});
