
import { Vue, Component, Prop, Watch, InjectReactive } from "vue-property-decorator";
import { layersTitle as layersType, layerTypeMap } from "@/config/layerConfig"
import MovableViewModal from "@/components/MovableView/MovableViewModal.vue"
import { saveFeatureRef } from "@/config/api";


@Component({
    components: { MovableViewModal }
})
export default class AddRelationInfo extends Vue {
  confirmLoading: boolean = false;
  visible: boolean = false;
  formData: any = {
    featureId: '',
    featureRefType: 0,
    refFeatureIds: [],
    sequence:'after'
  }
  relationFeas: any = {};
  index: number = 0;

  laneAndLane: any = {
    "after": "后车道",
    "before": "前车道"
  };

  @Prop() relationFeatures?: any;
  @Prop() selectedId?: any;
  @Prop() selectedLayerType?: any;

  @InjectReactive() selectedAssociationFeature!: any;


  @Watch('selectedAssociationFeature', { immediate: true })
  selectedFeatureChange(featureId: string) {
    if (!this.formData.refFeatureIds.includes(featureId)) {
      if (this.formData.refFeatureIds.length) {
        let flag = false;
        for (let i = 0; i < this.formData.refFeatureIds.length; i++) {
          if (!this.formData.refFeatureIds[i]) {
            flag = true;
            this.$set(this.formData.refFeatureIds, i, featureId);
            return;
          }
        }
        if (!flag) {
          this.$set(this.formData.refFeatureIds, 0, featureId);
        }
      } else {
        this.$set(this.formData.refFeatureIds, 0, featureId);
      }
    }
  }

  showModal() {
    this.visible = true;
    for (let item in layersType) {
      if (this.relationFeatures?.includes(layersType[item])) {
        this.relationFeas[item] = layersType[item];
      }
    }
    this.formData.featureId = this.selectedId;
    this.formData.refFeatureIds[0] = '';
    this.formData.featureRefType = Object.keys(this.relationFeas)[0];
    this.selectMap();
  }


  clearRelationData() {
    this.formData.refFeatureIds.forEach((item: any, index: number) => {
      this.$set(this.formData.refFeatureIds, index, '');
    })
  }

  selectMap() {
    const key: any = layerTypeMap[this.formData.featureRefType].key;
    this.$emit('selectedMap', key);
  }


  // 关联要素 Change
  selectChange(newVal: any) {
    this.clearRelationData();
    this.index = 0;
    this.selectMap();
    return newVal;
  }


  addInput() {
    this.index++;
    this.formData.refFeatureIds[this.index] = '';
  }


  handleOk() {
    let data: any = Object.assign({}, this.formData, {featureType: String(this.selectedLayerType)});
    // 只有  lane 和 lane  才有 sequence 属性
    if(data.featureType != data.featureRefType){
      delete data['sequence'];
    }
    let dataTemp: boolean = data?.refFeatureIds.every((value: string) => value === '');
    if (dataTemp) {
      this.$message.warning('请选择要素ID');
      return;
    }
    this.confirmLoading = true;
    saveFeatureRef(data).then(async (res: any) => {
      this.handleCancel();
      this.$message.success('添加关联关系成功');
      this.$emit('refresh');
    }).finally(() => {
      this.confirmLoading = false
    })
  }

    handleCancel() {
      this.visible = false;
      this.confirmLoading = false;
      this.$emit('removeSelectMap');
    }

}
