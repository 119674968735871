import { Fill, Stroke, Style, Circle, Icon } from "ol/style"
import { Feature } from "ol"
import { getCenter } from "ol/extent";
import { Point } from "ol/geom";
import Colors from "@/common/extend/Colors";

function defaultStyle() {
    return [
        new Style({
            stroke: new Stroke({
                color: '#096dd9',
                width: 2,
                lineDash: [0]
            }),
            fill: new Fill({
                color: [255, 255, 255, .2]
            }),
            image: new Circle({
                radius: 2,
                fill: new Fill({ color: '#096dd9' })
            })
        })
    ]
}
export default defaultStyle

export const Icon_Style = (feature: Feature<any>, zoom: number) => {
    const layerType = feature.get('layerType');
    let src: any = null;
    
    if (layerType == 1010) { // obstacle
        src = require(`@/assets/icons/obstacle.png`);
    }
    if (layerType == 1011) { // pole
        src = require(`@/assets/icons/light_pole.png`);
    }
    if (layerType == 1006) { // traffic_light
        src = require(`@/assets/icons/traffic_light.png`);
    }
    return [
        new Style({
            image: new Icon({
                src: src,
                scale: zoom < 18 ? 0.1 : 0.6,
                rotateWithView: true,
            }),
            geometry: (f: any) => new Point(getCenter(feature.getGeometry().getExtent()))
        })
    ]
}


export const LaneStyle = () => {
    let styles = [
        new Style({
            stroke: new Stroke({
                color: [0, 255, 255],
                width: 1,
                lineDash: [5, 5]
            })
        })
    ]
    return styles
}


export const StopLineStyle = () => {
    let styles = [
        new Style({
            fill: new Fill({ color: Colors['transparent_white'] }),
            stroke: new Stroke({
                color: Colors['orange'],
                width: 1
            }),
            image: new Circle({
                radius: 3,
                fill: new Fill({ color: Colors['orange'] })
            })
        })
    ]
    return styles
}
