import { Interaction } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import { Collection, Feature } from "ol";

import {
    lineString,
    multiPoint,
    Feature as T_Feature,
    LineString as T_LineString,
    FeatureCollection,
    Position
} from "@turf/helpers"
import truncate from "@turf/truncate";
import lineIntersect from "@turf/line-intersect";
import { getCoords } from "@turf/invariant";
import lineSplit from "@turf/line-split";
import { Geometry, LineString } from "ol/geom";
import { DrawEvent } from "ol/interaction/Draw";
import BaseEvent from "ol/events/Event";

import Draw from "@/common/Interactions/Draw";
import { Coordinate } from "ol/coordinate";
import { getLayerType } from "@/config/layerConfig";
import { Distance } from "@/common/utils/CommonUtil";

const hitTolerance = 0.02

interface SplitterOptions {
    features?: Feature<any>[];
    source?: VectorSource<any>;
    filter?: (_: Feature<any>) => boolean;
    bound?: Geometry | undefined;
    layout?: string;
}

export class BeforeSplitEvent extends BaseEvent {
    feature: Feature<any>;
    source: VectorSource<any>;
    constructor(type: string, feature: Feature<any>, source: VectorSource<any>) {
        super(type);
        this.feature = feature;
        this.source = source;
    }
}


export class AfterSplitEvent extends BaseEvent {
    featureAdded: Feature<any>[];
    featureRemoved: Feature<any>[];
    source: VectorSource<any>;
    constructor(type: string, featureAdded: Feature<any>[], featureRemoved: Feature<any>[], source: VectorSource<any>) {
        super(type);
        this.featureAdded = featureAdded;
        this.featureRemoved = featureRemoved;
        this.source = source;
    }
}

export default class Splitter extends Interaction {
    private _added: Feature<any>[];
    private _removed: Feature<any>[];
    private condition?: boolean;
    private readonly _source: VectorSource<any>;
    private readonly _layout: string;
    private readonly _filter: (_: Feature<any>) => boolean;

    constructor(options: SplitterOptions) {
        super();

        this._added = [];
        this._removed = [];
        if (options.features) {
            this._source = new VectorSource({ features: options.features });
        } else {
            this._source = options.source ? options.source : new VectorSource({ features: new Collection });
        }
        this._filter = options.filter || (() => true);
        this._layout = options.layout || "XYZM";
    }


    splitSource(feature: Feature<any>) {
        let k, f2;
        this._source.dispatchEvent(new BeforeSplitEvent('beforesplit', feature, this._source));
        this._added = [];
        this._removed = [];
        const geometry = feature.getGeometry();
        const c = this.getSplitterCoords(geometry);
        const extent = geometry.getExtent();
        this._source.forEachFeatureIntersectingExtent(extent, f => {
            this.condition = f !== feature && f?.getGeometry() instanceof LineString && this._filter(f);
            if (this.condition) {
                const coordinates = f?.getGeometry().getCoordinates();
                const line = lineString(coordinates);

                const decimalLine = truncate(line, { precision: 7 });
                const decimalCoords = getCoords(decimalLine);

                const splitter = lineString(c);
                const splitterMultiPt = this._getSplitterMultiPt(line, splitter);
                if (!splitterMultiPt) {
                    return
                }
                const geom = lineSplit(decimalLine, splitterMultiPt);
                const lines = this._filterNearPoint(geom, decimalCoords);

                if (lines && lines.length > 1) {
                    this._source.removeFeature(f);
                    this._removeFeatureToStack(f);

                    for (k = 0; k < lines.length; k++) {
                        f2 = f.clone();
                        f2.setGeometry(lines[k]);
                        f2.set('tag', 1);
                        this._source.addFeature(f2);
                        this._addFeatureToStack(f2);
                    }
                }
            }
        })
    }

    getSplitterCoords(geometry: LineString): Coordinate[] {
        const coordinates = geometry.getCoordinates();
        if (coordinates.length > 2) {
            const start = coordinates[0];
            const middle = coordinates[1];
            const end = coordinates[2];
            const angle = this.getAngle([start, middle], [middle, end]);
            if (Math.round(angle) == 90 || Math.round(angle) == 270) {
                coordinates.splice(0, 2);
                coordinates.unshift(this.prefixPoint(middle, end));
            }
        }
        return coordinates;
    }

    prefixPoint(start: number[], end: number[]): number[] {
        if (end[0] - start[0] === 0) {
            return start;
        }

        const k = (end[1] - start[1]) / (end[0] - start[0]);
        const f = (x: number) => k * x + end[1] - k * end[0];
        if (start[0] > end[0]) {
            return [start[0] + hitTolerance, f(start[0] + hitTolerance)];
        }

        if (start[0] < end[0]) {
            return [start[0] - hitTolerance, f(start[0] - hitTolerance)];
        }
        return start
    }


    getAngle(coords1: number[][], coords2: number[][]): number {
        const point1 = [
            coords1[0][0] - coords1[1][0],
            coords1[0][1] - coords1[1][1]
        ]
        const point2 = [
            coords2[1][0] - coords2[0][0],
            coords2[1][1] - coords2[0][1]
        ]
        const dot = point1[0] * point2[0] + point1[1] * point2[1];
        const det = point1[0] * point2[1] - point1[1] * point2[0];
        const angle = Math.atan2(det, dot) / Math.PI * 180;
        return (angle + 360) % 360;
    }

    _getSplitterMultiPt(line: T_Feature<T_LineString>, splitter: T_Feature<T_LineString>) {
        const intersectSet = lineIntersect(line, splitter);
        const points : any = intersectSet.features.map(f => f.geometry.coordinates);

        // 交点与首尾点判断
        if(points.length <=0 ) return false;
        const pointsNew : any[] = new Array();
        points.forEach((item:any, index:number)=>{
            let pointS = line.geometry.coordinates[0];
            let pointE = line.geometry.coordinates[line.geometry.coordinates.length - 1];
            let distanceS = Distance([pointS, item]);
            let distanceE = Distance([pointE, item]);
            // 如果交点是首尾点，也不算相交
            if(distanceS >= 0.005 && distanceE  >= 0.005){
                pointsNew.push([...item]);
            }
        })
        if(pointsNew.length <=0 ) return false;

        return multiPoint(pointsNew);
    }

    _getNewGeomtry(oldCoordinates: Position[], newCoordinates: Position[]) {
        const coords = newCoordinates;
        coords.forEach((coord, index) => {
            const same = oldCoordinates.find(e => (e[0] == coord[0] && e[1] && coord[1]));
            if (same) {
                coord[2] = same[2]
            } else {
                const nearP = index === 0 ? coords[1] : coords[coords.length - 2];
                const j = oldCoordinates.findIndex(e => (e[0] == nearP[0] && e[1] && nearP[1]));
                const controlCoords = [];
                if (index == 0) {
                    controlCoords[0] = this._getCoordinate(oldCoordinates, j - 1, -1);
                    controlCoords[1] = this._getCoordinate(oldCoordinates, j, 1);
                } else {
                    controlCoords[0] = this._getCoordinate(oldCoordinates, j, -1);
                    controlCoords[1] = this._getCoordinate(oldCoordinates, j + 1, 1);
                }
            }
        })
    }

    _getCoordinate(coordinates: Position[], num: number, step: number): Position {
        if (coordinates[num]) {
            if (coordinates[num][2] === 0) {
                this._getCoordinate(coordinates, num + step, step);
            } else {
                return coordinates[num];
            }
        }
        return [];
    }

    _filterNearPoint(featureCollection: FeatureCollection<T_LineString>, oldCoordinates: any[]): LineString[] {
        const lines: LineString[] = [];
        featureCollection.features.forEach((feature: any) => {
            this._getNewGeomtry(oldCoordinates, feature.geometry.coordinates);
            const coordinates = [...feature.geometry.coordinates];
            if (coordinates.length > 1) {
                if (Distance(coordinates) < hitTolerance) {
                    coordinates.splice(1, 1);
                }

                if (Distance([coordinates[coordinates.length - 1], coordinates[coordinates.length - 2]]) < hitTolerance) {
                    coordinates.splice(coordinates.length - 2, 1);
                }

                lines.push(new LineString(coordinates, this._layout));
            }
        })
        return lines
    }

    setDrawInteraction(draw?: Draw) {
        draw?.on('drawend', this._drawEnd.bind(this));
    }

    _drawEnd(e: DrawEvent) {
        this.splitSource(e.feature);
        setTimeout(() => {
            this._source.removeFeature(e.feature);
            this._source.dispatchEvent(new AfterSplitEvent('aftersplit', this._added, this._removed, this._source));
        }, 0)
    }
    _addFeatureToStack(feature: Feature<any>) {
        this._added.push(feature);
    }

    _removeFeatureToStack(feature: Feature<any>) {
        const n = this._added.indexOf(feature);
        if (n == -1) {
            this._removed.push(feature);
        } else {
            this._added.splice(n, 1);
        }
    }
}
