import { Style, Stroke, Fill, Circle, Icon, RegularShape} from "ol/style"
import Color from "@/common/extend/Colors";

export const posStyle= {
    pos:new Style({
        image:new Circle({
            radius:7,
            fill:new Fill({
                color:'green'
            })
        })
    }),
    posSel:new Style({
        image:new Circle({
            radius:8,
            fill:new Fill({
                color:Color.posSel
            })
        })
    })
}
export const selectVect = new Style({
        stroke: new Stroke({
            color: Color['orange'],
            width: 3
        }),
        // fill: new Fill({color: Color['red']}),
    }
)