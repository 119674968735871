import { Map as olMap, Feature, MapBrowserEvent, } from "ol";
import RenderFeature from "ol/render/Feature";
import VectorSource from "ol/source/Vector";
import { Layer, Vector as VectorLayer } from "ol/layer";

import { Point, LineString } from "ol/geom";
import BaseStyle from "ol/style/Style"

import Event from "ol/events/Event";
import { EventsKey } from "ol/events";
import { shiftKeyOnly } from "ol/events/condition";
import { InteractionOnSignature } from "ol/interaction/Interaction";

import BoxPointer from "@/common/Interactions/BoxPointer";
import { ol_coordinate_dist2d, getNearestCoord } from "@/common/utils/CommonUtil"

import StyleBuilder from "@/common/extend/StyleBuilder"


type SelectVerticesOnSignature<Return> = InteractionOnSignature<EventsKey> & (
    (
        type: 'afterSelectVertices' | ('afterSelectVertices')[],
        listener: (event: any) => any
    ) => Return
)

class SelectVerticesEvent extends Event {
    selectVerticesList: Feature<any>[];

    constructor(type: string, selectVerticesList: Feature<any>[]) {
        super(type);
        this.selectVerticesList = selectVerticesList;
    }
}

export default class SelectVertices extends BoxPointer {
    private readonly _filter: (_: Feature<any> | RenderFeature, layer: Layer<any>) => boolean;
    private readonly _hitTolerance: number;
    private readonly _hoverStyle: BaseStyle | BaseStyle[] | ((_: Feature<any> | RenderFeature) => BaseStyle | BaseStyle[]);

    private readonly _overlayLayer: VectorLayer<VectorSource<any>>;
    private readonly _onRemoveFeatureEvent: Function;
    private _sources: VectorSource<any>[];
    private _selectedFeatures: any[];
    private moveSelected: Feature<any>[];

    declare public on: SelectVerticesOnSignature<EventsKey>

    constructor(options: any) {
        super(options);

        this._filter = options.filter || (() => true);
        this._hitTolerance = options.hitTolerance || 10;
        this._hoverStyle = options.hoverStyle || StyleBuilder.SelectVertices(false);

        this._overlayLayer = new VectorLayer({
            source: new VectorSource({ useSpatialIndex: false }),
            style: StyleBuilder.SelectVertices(false)
        })
        this._onRemoveFeatureEvent = this._onRemoveFeature.bind(this);

        this._sources = options.sources || [];
        this._sources.forEach((source: any) => source?.on('removefeature', this._onRemoveFeatureEvent));

        this._selectedFeatures = [];
        this.moveSelected = [];
    }

    setMap(map: olMap) {
        if (this.getMap()) {
            this._sources.forEach((source: any) => source?.un("removefeature", this._onRemoveFeatureEvent))
            this.getMap()?.removeLayer(this._overlayLayer);
        }
        super.setMap(map);
        this._overlayLayer.setMap(map);
    }

    private _onRemoveFeature(data: { feature: Feature<any> | RenderFeature }): void {
        const feature = this._selectedFeatures.find(item => item.feature == data.feature);

        if (feature) {
            const MAP = this.getFeaturesMap();
            const coords = MAP.get(data.feature);
            const removes: any[] = [];

            this._overlayLayer.getSource()?.getFeatures().forEach((f: Feature<any>) => {
                const p = f.getGeometry().getFirstCoordinate();
                for (const coord of coords) {
                    if (p.toString() == coord.toString()) {
                        removes.push(f);
                    }
                }
            })
            if (removes.length > 0) {
                removes.forEach(f => this._overlayLayer.getSource()?.removeFeature(f));
            }

            const index = this._selectedFeatures.indexOf(feature);
            this._selectedFeatures.splice(index, 1);
        }
    }

    private getFeaturesMap() {
        const map = new Map();
        this._selectedFeatures.forEach((data: { feature: any, coord: number[] }) => {
            if (map.has(data.feature)) {
                map.get(data.feature).push(data.coord);
            } else {
                map.set(data.feature, [data.coord]);
            }
        })
        return map
    }

    public setSources(sources: VectorSource<any>[] | VectorSource<any>): void {
        this._sources.forEach((source: any) => source?.un("removefeature", this._onRemoveFeatureEvent))
        this._sources = sources instanceof Array ? sources : [sources];
        this._sources.forEach((source: any) => source?.on("removefeature", this._onRemoveFeatureEvent))
    }

    _addVertex(data: { feature: any, coord: number[] }) {
        let hasCoordinate = false;
        const MAP = this.getFeaturesMap();
        MAP.forEach((value: any, key: any) => {
            if (key.ol_uid == data.feature.ol_uid) {
                value.forEach((item: number[]) => {
                    if (item[0] == data.coord[0] && item[1] == data.coord[1] && item[2] == data.coord[2]) {
                        hasCoordinate = true;
                    }
                })
            }
        })

        if (!hasCoordinate) {
            const POINT = new Feature(new Point(data.coord));
            this._overlayLayer.getSource()?.addFeature(POINT);
            this._selectedFeatures.push(data);
        }
    }

    clearVertex() {
        this._overlayLayer.getSource()?.clear();
        this._selectedFeatures = [];
    }

    // 查询坐标高亮点
    public pointHighLight(xy: number[]) {
        const features = this._overlayLayer.getSource()?.getFeatures();
        features?.forEach(feature => {
            feature.setStyle(undefined);
            const coord = feature.getGeometry().getCoordinates();
            if (coord[0] == xy[0] && coord[1] == xy[1]) {
                feature.setStyle(StyleBuilder.SelectVertices(true));
            }
        })
    }

    protected onBoxEnd(mapBrowserEvent: MapBrowserEvent<any>): void {
        const extent = this.getGeometry().getExtent();
        this._sources.forEach((vector: VectorSource<any>) => {
            const source: VectorSource<any> = this._getVisibleSource(vector);
            if (source) {
                source.forEachFeatureIntersectingExtent(extent, (feature: Feature<any>) => {
                    let coords = [];
                    if (feature.getGeometry().getType() == 'LineString') {
                        coords = feature.getGeometry().getCoordinates();
                    }
                    if (feature.getGeometry().getType() == 'Polygon') {
                        coords = feature.getGeometry().getCoordinates().flat();
                    }
                    coords.forEach((coord: number[]) => {
                        if (this.getGeometry().intersectsCoordinate(coord)) {
                            this._addVertex({ feature, coord })
                        }
                    })
                })
            }

        })
    }

    private _getVisibleSource(source: VectorSource<any>): any {
        let vector = null;
        const layers = this.getMap()?.getLayers().getArray();
        layers?.forEach((layer: any) => {
            if (layer.getVisible() && (layer.getSource() == source)) {
                vector = layer.getSource();
            }
        })
        return vector;
    }

    protected handleDownEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        if (!shiftKeyOnly(mapBrowserEvent)) {
            this.clearVertex();
        }
        const current: any = this.getClosestFeature(mapBrowserEvent);
        if (current) {
            this._addVertex(current);
            return true;
        }
        return super.handleDownEvent(mapBrowserEvent);
    }

    protected handleUpEvent(mapBrowserEvent: MapBrowserEvent<any>): boolean {
        super.handleUpEvent(mapBrowserEvent);
        this.dispatchEvent(new SelectVerticesEvent('afterSelectVertices', this._selectedFeatures));
        return false;
    }

    protected handleMoveEvent(mapBrowserEvent: MapBrowserEvent<any>): void {
        // const feature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (f: any, layer: any) => {
        //     if (layer && this._sources.includes(layer?.getSource())) {
        //         return f as Feature<any>
        //     } else {
        //         return false
        //     }
        // }, { hitTolerance: 10 })
        //
        // if (feature) {
        //     feature.setStyle(this._hoverStyle);
        //     this.moveSelected.push(feature);
        // } else {
        //     this.moveSelected.forEach(f => f.setStyle(undefined));
        //     this.moveSelected = [];
        // }
    }

    getClosestFeature(mapBrowserEvent: any) {
        let snapDistance: number = this._hitTolerance + 1;
        let closestFeature: any = null;
        let NPoint: any = null;

        const feature = mapBrowserEvent.map.forEachFeatureAtPixel(mapBrowserEvent.pixel, (f: any, layer: any) => {
            if (this._filter(f, layer)) {
                return f as Feature<any>
            }
            return false
        })

        if (feature) {
            const point = feature.getGeometry().getClosestPoint(mapBrowserEvent.coordinate);
            const line = new LineString([mapBrowserEvent.coordinate, point]);
            const d = line.getLength() / mapBrowserEvent.frameState.viewState.resolution;
            if (d < snapDistance) {
                snapDistance = d;
                closestFeature = feature;
                NPoint = point;
            }
        }

        if (snapDistance > this._hitTolerance) {
            return false;
        }

        if (closestFeature) {
            const coords = getNearestCoord(NPoint, closestFeature?.getGeometry());
            if (coords) {
                const p: any = this.getMap()?.getPixelFromCoordinate(coords);
                if (ol_coordinate_dist2d(mapBrowserEvent.pixel, p) < this._hitTolerance) {
                    return { feature: closestFeature, coord: coords }
                }
            }
        }
        return false;
    }
}
