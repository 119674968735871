
export default<Record<string, number[]>> {
    'red': [255, 0, 0],
    'white': [255, 255, 255],
    'yellow': [255, 255, 0],
    'black': [0, 0, 0],
    'pink': [255, 128, 192],
    'magenta': [255, 0, 255],
    'orange': [250, 128, 10],
    'blue': [67, 142, 219],
    'cyan': [0, 255, 255],
    'light_yellow': [255, 255, 205],
    'light_green': [171, 245, 176],
    'light_black': [8, 46, 84],
    'light_purple': [200, 147, 255],
    'drak_green': [37, 124, 64],
    'rose_red': [251, 65, 65],
    'bright_yellow': [252, 135, 7],
    'rose_blue': [9, 109, 217],
    'select_blue': [51, 153, 204],
    'select_yellow': [255, 204, 51],
    'transparent_white': [255, 255, 255, .2],
    'transparent_gray': [255, 255, 255, .5],
    'pos': [0, 255, 0],
    'posSel': [160, 32, 240]
}