
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
@Component
export default class MovableViewModal extends Vue {
    @Prop() width?: number;
    @Prop() height?: any;
    @Prop() minHeight?: number;

    @Prop() visible?: boolean;
    @Prop() title?: any;

    minWidth: any = '500px';

    @Watch("width", { immediate: true})
    change(val: number) {
        if (val > 500) {
            this.minWidth = val + 'px'
        }
    }
    
    @Emit("close")
    close() {}

    mousedown(event: any) {
        event.stopPropagation();
        let div: any = document.getElementById('modal');
        document.addEventListener("mousemove", dragMove);
        let disX = event.clientX - div.offsetLeft;
        let disY = event.clientY - div.offsetTop;

        function dragMove(e: any) {
            if (e.target.nodeName == "CANVAS") {
                return false;
            }
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            div.style.transition = "unset";
            div.style.left = left + "px";
            div.style.top = top + "px";
        }

        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", dragMove);
        });
    }
}
